import Swiper, { Navigation, Autoplay } from 'swiper';

const sliders = () => {
	//------------------------------------//
	// SWIPER - Coach
	//------------------------------------//
	const swiperCoach = new Swiper('.swiper-coach', {
		// Optional parameters
		modules: [Navigation],
		loop: true,
		slidesPerView: 1.25,
		spaceBetween: 30,

		// Navigation arrows
		navigation: {
			nextEl: '.coaches .btn-next',
			prevEl: '.coaches .btn-prev'
		},
		breakpoints: {
			// when window width is >= 320px
			600: {
				slidesPerView: 1.5
			},
			// when window width is >= 480px
			768: {
				slidesPerView: 2
			},
			// when window width is >= 640px
			992: {
				slidesPerView: 3
			}
		}
	});

	//------------------------------------//
	// SWIPER - Review
	//------------------------------------//
	const swiperReview = new Swiper('.swiper-review', {
		// Optional parameters
		loop: false,
		modules: [Navigation],
		slidesPerView: 1.25,
		spaceBetween: 30,

		// Navigation arrows
		navigation: {
			nextEl: '.reviews .btn-next',
			prevEl: '.reviews .btn-prev'
		},
		breakpoints: {
			// when window width is >= 320px
			600: {
				slidesPerView: 1.25
			},

			992: {
				slidesPerView: 1.5
			}
		}
	});

	//------------------------------------//
	// SWIPER - What
	//------------------------------------//
	const swiperWhat = new Swiper('.swiper-what', {
		// Optional parameters
		loop: false,
		modules: [Navigation],
		slidesPerView: 1,
		spaceBetween: 30,
		// Navigation arrows
		navigation: {
			nextEl: '.what .btn-next',
			prevEl: '.what .btn-prev'
		},
		breakpoints: {
			// when window width is >= 320px
			600: {
				slidesPerView: 1.5
			},
			// when window width is >= 480px
			768: {
				slidesPerView: 2
			},
			// when window width is >= 640px
			1025: {
				slidesPerView: 3,
				spaceBetween: 90
			}
		}
	});

	//------------------------------------//
	// SWIPER - Coach
	//------------------------------------//
	const swiperCustomers = new Swiper('.swiper-customers', {
		// Optional parameters
		loop: true,
		modules: [Autoplay],
		slidesPerView: 2.5,
		spaceBetween: 60,
		autoplay: {
			delay: 3000
		},
		breakpoints: {
			// when window width is >= 320px
			600: {
				slidesPerView: 3
			},
			// when window width is >= 480px
			768: {
				slidesPerView: 4
			},
			// when window width is >= 640px
			992: {
				slidesPerView: 6
			}
		}
	});

	//------------------------------------//
	// SWIPER - Result
	//------------------------------------//
	const swiperResult = new Swiper('.swiper-result', {
		// Optional parameters
		loop: false,
		modules: [Navigation],
		slidesPerView: 1.25,
		spaceBetween: 30,

		// Navigation arrows
		navigation: {
			nextEl: '.results [data-col="nav"] .btn-next',
			prevEl: '.results [data-col="nav"] .btn-prev'
		},
		breakpoints: {
			// when window width is >= 320px
			600: {
				slidesPerView: 1.5
			},
			// when window width is >= 480px
			768: {
				slidesPerView: 2
			},
			// when window width is >= 640px
			992: {
				slidesPerView: 3
			}
		}
	});
};

export default sliders;
