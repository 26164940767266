import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite
import { Fancybox } from '@fancyapps/ui';
import sliders from './modules/sliders.js';
import fetchPosts from './modules/fetch-posts.js';

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	sliders();
	new Modal();

	if (document.querySelector('.card[data-card="result"]')) {
		fetchPosts();
	}

	Fancybox.bind('[data-fancybox]');
}

// Fancybox.bind('[data-fancybox]', {
// 	// Your options go here
// });

jQuery(document).ready(function ($) {
	init();
});

//------------------------------------//
// DELETE ACTIVE STATE
//------------------------------------//
const removeActive = function (selector) {
	[...document.querySelectorAll(selector)].forEach(
		(el) => delete el.dataset.active
	);
};

//------------------------------------//
// ACCORDION
//------------------------------------//

const accordionTriggers = document.querySelectorAll(
	'.item-list[data-list="accordion"] .item-trigger'
);

[...accordionTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchItem);
});

function switchItem(e) {
	removeActive('.item-list[data-list="accordion"] .item[data-active]');
	e.currentTarget.parentNode.dataset.active = true;
}
