const mobileMenu = () => {
	// Hamburger menu
	const $body = document.querySelector('body');
	const $hamburger = document.querySelector('.header-nav button');

	$hamburger &&
		$hamburger.addEventListener('click', () => {
			$body.classList.toggle('menu-is-open');
		});
};

export default mobileMenu;
