import Swiper, { Navigation, Pagination } from 'swiper';
import { Fancybox } from '@fancyapps/ui';

/**
 * Fetches posts and initializes event handlers for displaying post details in a modal.
 */
const fetchPosts = () => {
	const posts = document.querySelectorAll('.card[data-card="result"]');
	const modal = document.querySelector('.dialog-post');
	const modalMediaContainer = modal.querySelector('.post-media');
	const modalContentContainer = modal.querySelector('.post-content-inner');
	const responseCache = new Map();

	/**
	 * Initializes Swiper instances for the modal media container.
	 */
	const initializeSwipers = () => {
		console.log('initializeSwipers');
		const modalSwiperEl = modalMediaContainer.querySelector('.swiper');

		const modalSwiper = new Swiper(modalSwiperEl, {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			spaceBetween: 0,
			speed: 600,
			loop: true,
			pagination: {
				el: '.dialog-post .swiper-pagination',
				clickable: true
			},
			navigation: {
				nextEl: '.dialog-post .swiper-next',
				prevEl: '.dialog-post .swiper-prev'
			}
		});

		return modalSwiper;
	};

	const destroySwipers = () => {
		const swipers = modalMediaContainer.querySelectorAll('.swiper');
		swipers.forEach((swiper) => {
			if (swiper.swiper) {
				swiper.swiper.destroy(true, true);
			}
		});
	};

	const fetchData = async (postId) => {
		if (responseCache.has(postId)) {
			return responseCache.get(postId);
		}

		try {
			const response = await fetch(`/wp-json/xpl/posts/${postId}`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			responseCache.set(postId, data); // Cache the data
			return data;
		} catch (error) {
			console.error('Fetch error:', error);
			return null;
		}
	};

	const clickHandler = async (e) => {
		// delay opening fancybox until fetch is complete
		e.preventDefault();

		const postId = e.currentTarget.dataset.post;
		const data = await fetchData(postId);

		if (data) {
			modalMediaContainer.innerHTML = data.post_media_html;
			modalContentContainer.innerHTML = data.post_content_html;

			//modal.showModal('open');

			// open fancybox
			//const fancybox = Fancybox.getInstance();

			Fancybox.show([{ src: '#dialog-post', type: 'inline' }]);

			setTimeout(() => {
				const modalSwiper = initializeSwipers();
				//handleFancybox(); // Initialize FancyBox if any
			}, 200);
		}
	};

	posts.forEach((post) => {
		post.querySelector('.btn').addEventListener('click', clickHandler);
	});

	modal.addEventListener('close', destroySwipers);
};

export default fetchPosts;
